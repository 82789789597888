import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuctionEventsService} from '../../_services/auction-events.service';
import {AuctionEvent} from '../../_models/auction-event';
import {UsersService} from '../../_services/users.service';
import {User} from '../../_models/user';
import {BookingsService} from '../../_services/bookings.service';
import {Observable} from 'rxjs';
import {ViewChild, ElementRef} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {ListsService} from '../../_services/lists.service';
import {AuthService} from '../../_services/auth.service';

export interface IDirectors {
  value: number;
  label: string;
}

export interface IAgents {
  value: number;
  label: string;
}

export interface ISecondaryAgents {
  value: number;
  label: string;
}

@Component({
  selector: 'app-booking-add-dialog',
  templateUrl: './booking-add-dialog.component.html',
  styleUrls: ['./booking-add-dialog.component.css']
})

export class BookingAddDialogComponent implements OnInit {


  addBookingForm: FormGroup;
  User: any;
  UserID: any;
  regionName: any;

  auctionEvents: AuctionEvent[];


  // Regional Directors
  @ViewChild('regionalDirectorInput') regionalDirectorInput: ElementRef;
  regional_director_id: number;
  RegDirCtrl = new FormControl('Ben Brady');
  filteredDirectors: Observable<IDirectors[]>;
  director: IDirectors[] = [];
  default_regional_director = 'Ben Scott';
  default_regional_director_id = 2;

  // Listing Agents
  @ViewChild('agentInput') agentInput: ElementRef;
  listing_agent_id: number;
  agentCtrl = new FormControl();
  filteredAgents: Observable<IAgents[]>;
  agent: IAgents[] = [];

  // Secondary Listing Agents
  @ViewChild('secondaryAgentInput') secondaryAgentInput: ElementRef;
  secondary_listing_agent_id: number;
  secondaryAgentCtrl = new FormControl();
  filteredSecondaryAgents: Observable<ISecondaryAgents[]>;
  secondaryAgent: ISecondaryAgents[] = [];

  eventType = [
    {name: 'Event', value: 'Event'}
    // {name: 'On site', value: 'On site'}
  ];




  constructor(
    private dialogRef: MatDialogRef<BookingAddDialogComponent>,
    private auctionEventsService: AuctionEventsService,
    private bookingsService: BookingsService,
    private usersService: UsersService,
    private listsService: ListsService,
    private nativeElement: ElementRef,
    private authService: AuthService,
  ) {

    this.usersService.findListingAgentsList({
      filters: {'user_type': 'Listing Agent'},
      sort: 'id',
      pages: {'size': '1000'}
    });

    this.usersService.listingAgentsListLoaded.subscribe((data) => {
      for (let i = 0; i < data.length; i++) {
        this.agent.push(
          {
            value: data[i]['id'],
            label: data[i]['first_name'] + ' ' + data[i]['last_name']
          },
        );

        this.secondaryAgent.push(
          {
            value: data[i]['id'],
            label: data[i]['first_name'] + ' ' + data[i]['last_name']
          },
        );
      }
    });

    this.usersService.findRegionalDirectorsList({
      filters: {'user_type': 'Regional Director'}
    });

    this.usersService.regionalDirectorsListLoaded.subscribe((data) => {
      for (let i = 0; i < data.length; i++) {
        this.director.push(
          {
            value: data[i]['id'],
            label: data[i]['first_name'] + ' ' + data[i]['last_name']
          },
        );
      }
    });
  }

  private _filteredDirectors(value: string): IDirectors[] {
    const filterValue = value.toLowerCase();
    return this.director.filter(director => director.label.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAgents(value: string): IAgents[] {
    const filterValue = value.toLowerCase();
    return this.agent.filter(agent => agent.label.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterSecondaryAgents(value: string): ISecondaryAgents[] {
    const filterValue = value.toLowerCase();
    return this.secondaryAgent.filter(secondaryAgent => secondaryAgent.label.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {


    const lists = this.listsService.getData();

    this.filteredAgents = this.agentCtrl.valueChanges
      .pipe(
        startWith(''),
        map(agent => agent ? this._filterAgents(agent) : this.agent.slice())
      );

    this.filteredSecondaryAgents = this.secondaryAgentCtrl.valueChanges
      .pipe(
        startWith(''),
        map(secondaryAgent => secondaryAgent ? this._filterSecondaryAgents(secondaryAgent) : this.secondaryAgent.slice())
      );

    this.filteredDirectors = this.RegDirCtrl.valueChanges
      .pipe(
        startWith(''),
        map(director => director ? this._filteredDirectors(director) : this.director.slice())
      );

    this.auctionEvents = lists.auction_events;

    this.addBookingForm = new FormGroup({

      'listing_agent_id': new FormControl(null),
      'secondary_listing_agent_id': new FormControl(null),
      'address_line1': new FormControl(null, [Validators.required]),
      'address_line2': new FormControl(null),
      'address_city': new FormControl(null),
      'address_state': new FormControl(null),
      'address_zip': new FormControl(null),
      'regional_director_id': new FormControl(null),
      'auction_location': new FormControl(null),
      'auction_date': new FormControl(null),
      'auction_time': new FormControl(null),
      'auction_event_id': new FormControl(null),
      // 'auction_address_line1': new FormControl(null),
      // 'auction_address_line2': new FormControl(null),
      // 'auction_address_city': new FormControl(null),
      // 'auction_address_state': new FormControl(null),
      // 'auction_address_zip': new FormControl(null)
    });

    this.addBookingForm.controls['regional_director_id'].setValue(this.default_regional_director_id);

// Set Default value for listing agent

    this.User = this.authService.getUser().user.first_name + ' ' + this.authService.getUser().user.last_name;

    this.UserID = this.authService.getUser().user.id;
    this.regionName = this.authService.getUser().user.region_name;
    const regionId = this.authService.getUser().user.region_id;
    if (regionId === 9) {
      this.RegDirCtrl = new FormControl('Ben Brady');
      this.default_regional_director = 'Ben Brady';
      this.default_regional_director_id = 2;
    } else {
      this.RegDirCtrl = new FormControl('Ben Brady'); // 👈 change
      this.default_regional_director = 'Ben Brady'; // 👈 change
      this.default_regional_director_id = 2;  // 👈 change
    }

    console.log('REG NAME', this.regionName);
    //console.log(this.UserID);
    this.agentCtrl.setValue(this.User);
    this.addBookingForm.controls['listing_agent_id'].setValue(this.UserID);

  }


  onCreateBooking() {

    const fields = this.addBookingForm.value;

    fields.regional_director_id = (typeof fields.regional_director_id === 'undefined') ? this.default_regional_director_id : fields.regional_director_id;

    this.bookingsService.insert(fields, this.dialogRef);

    // this.dialogRef.close();
  }

  getDirectors(name) {
    // console.log('name' + name);\
    this.usersService.getRegionalDirectorByName(name);
    this.usersService.regionalDirectorDetailsLoaded.subscribe(data => {
      this.addBookingForm.controls['regional_director_id'].setValue(data[0].id);
    });

  }

  getAgents(agentname) {
    // console.log('name=' + agentname);
    this.usersService.getUserByName(agentname);
    this.usersService.userdetailsLoaded.subscribe(data => {
      this.addBookingForm.controls['listing_agent_id'].setValue(data[0].id);
    });
  }

  getSecondaryAgents(agentname) {
    // console.log('name=' + agentname);
    this.usersService.getSecondaryUserByName(agentname);
    this.usersService.secondaryUserDetailsLoaded.subscribe(data => {
      this.addBookingForm.controls['secondary_listing_agent_id'].setValue(data[0].id);
    });
  }
}
